import { ButtonIcon } from 'src/general/components/Button/ButtonIcon';
import { IconCircleCrossFilled } from 'src/general/Icons/IconCircleCrossFilled';
import styles from './carComparisonDismissSnackbarAction.module.scss';
import { ToastContentProps } from 'react-toastify';

export const carComparisonDismissSnackbarAction = ({ closeToast }: Pick<ToastContentProps, 'closeToast'>) => (
  <ButtonIcon
    className={styles.buttonDismiss}
    Icon={IconCircleCrossFilled}
    title="Dismiss"
    onClick={closeToast}
    variant="tertiary"
    size="xsmall"
    styling="round"
  />
);
