import { ApolloProvider } from '@apollo/client';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import classNames from 'classnames';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import NextNProgress from 'nextjs-progressbar';
import { FC, useEffect } from 'react';

import ErrorBoundary from 'src/components/ErrorBoundary';
import { PreviewSnackbar } from 'src/general/components/PreviewSnackbar/PreviewSnackbar';
import { RouterHistoryManager } from 'src/general/components/RouterHistoryManager/RouterHistoryManager';
import { ThirdPartyLibraries } from 'src/general/components/ThirdPartyLibraries/ThirdPartyLibraries';
import { WebsiteHelpers } from 'src/general/components/WebsiteHelpers/WebsiteHelpers';
import { initCarmaEventTracker, pushCarmaEvent, removeCarmaEventTracker } from 'src/general/helpers/carmaCritical';
import { arminFont } from 'src/general/helpers/fontsArmin';
import { carmaTitleFont } from 'src/general/helpers/fontsCarmaTitle';
import { StoreProviders } from 'src/general/providers/StoreProviders/StoreProviders';
import cmsGraphClient from 'src/services/instance/cmsGraphClient';
import { CarmaErrorStoreProvider, useCreateCarmaErrorStore } from 'src/stores/errorStore';
import { MicrocopyStoreProvider, useCreateMicrocopyStore } from 'src/stores/microcopyStore';
import { PlpStoreLegacyProvider, useCreatePlpStoreLegacy } from 'src/stores/plpStoreLegacy';
import { RouterHistoryStoreProvider, useCreateRouterHistoryStore } from 'src/stores/routerHistoryStore';
import { themeCreated } from 'src/theme/MUI';
import { pushToDataLayer } from 'src/utils/pushToDataLayer';
import 'styles/globals.css';
import 'styles/helperClasses.scss';

const MyApp: FC<AppProps> = ({ Component, pageProps }) => {
  const { pathname, asPath, query } = useRouter();

  const createRouterHistoryStore = useCreateRouterHistoryStore({});
  const createPlpStore = useCreatePlpStoreLegacy(pageProps.initialPlpStoreState);
  const createMicrocopyStore = useCreateMicrocopyStore(
    pageProps.initialMicrocopyStoreState,
    pageProps.contentfulMicrocopy,
  );

  const createCarmaErrorStore = useCreateCarmaErrorStore(pageProps.contentfulCarmaError);

  function checkStyleLoaded() {
    const jssStyles: any = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }

  const persistSessionInfo = () => {
    // eslint-disable-next-line camelcase
    const { utm_medium: utmMedium, utm_source: utmSource, utm_campaign: utmCampaign } = query;
    if (utmSource) {
      window.sessionStorage.setItem('utmSource', utmSource as string);
    }
    if (utmMedium) {
      window.sessionStorage.setItem('utmMedium', utmMedium as string);
    }
    if (utmCampaign) {
      window.sessionStorage.setItem('utmCampaign', utmCampaign as string);
    }
  };

  useEffect(() => {
    initCarmaEventTracker();
    checkStyleLoaded();

    return () => {
      removeCarmaEventTracker();
    };
  }, []);

  useEffect(() => {
    persistSessionInfo();
  }, [query]);

  useEffect(() => {
    pushToDataLayer({ event: 'navigation', pathname: asPath });
    pushCarmaEvent({ event: 'navigation' });
  }, [pathname, asPath]);

  return (
    <>
      <Head>
        <title>Carma | The easy way to buy used cars in Australia</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        {/* https://nextjs.org/docs/messages/react-hydration-error#common-ios-issues */}
        <meta name="format-detection" content="telephone=no, date=no, email=no, address=no" />
      </Head>
      <style jsx global>{`
        :root {
          --font-armin: ${arminFont.style.fontFamily};
          --font-carmatitle: ${carmaTitleFont.style.fontFamily};
        }
      `}</style>
      <WebsiteHelpers />
      <ThemeProvider theme={themeCreated}>
        <CssBaseline />
        <NextNProgress color="#E83657" options={{ showSpinner: false }} />
        {pageProps.preview && <PreviewSnackbar />}
        <ApolloProvider client={cmsGraphClient}>
          {/* Global functionality related provider */}
          <RouterHistoryStoreProvider createStore={createRouterHistoryStore}>
            {/* Content related providers */}
            <MicrocopyStoreProvider createStore={createMicrocopyStore}>
              <CarmaErrorStoreProvider createStore={createCarmaErrorStore}>
                <PlpStoreLegacyProvider createStore={createPlpStore}>
                  {/* Auth related providers */}
                  <StoreProviders>
                    <ThirdPartyLibraries />
                    {/* Store managers */}
                    <RouterHistoryManager />
                    {/* Content */}
                    <main className={classNames(arminFont.variable, carmaTitleFont.variable)}>
                      <ErrorBoundary>
                        <Component {...pageProps} />
                      </ErrorBoundary>
                    </main>
                  </StoreProviders>
                </PlpStoreLegacyProvider>
              </CarmaErrorStoreProvider>
            </MicrocopyStoreProvider>
          </RouterHistoryStoreProvider>
        </ApolloProvider>
      </ThemeProvider>
    </>
  );
};

export default MyApp;
