export const enum FLEXIBLE_SECTION {
  CUSTOMER_REVIEWS = 'customerReviewsSection',
  CUSTOMER_REVIEWS_SCORE = 'customerReviewsScore',
  MEDIA_AND_CONTENT = 'mediaAndContent',
  MARKETING = 'marketing',
  RECENTLY_VIEWED_CAROUSEL = 'recentlyViewedCarousel',
  IMAGES_CAROUSEL = 'imagesCarousel',
  VEHICLE_CAROUSEL = 'vehicleCarousel',
  BROWSE_CARS = 'browseCars',
  HERO_TRADE_IN = 'heroTradeIn',
  HERO_HOMEPAGE = 'heroHomepage',
  CALLOUT_SECTION = 'calloutSection',
  FINANCE_HERO_BANNER = 'financeHeroBanner',
  FEATURE_SECTION = 'featureSection',
  DISCLAIMER_SECTION = 'disclaimerSection',
  RICH_TEXT_SECTION = 'richTextSection',
  ACCORDION_SECTION = 'accordionSection',
  DIVIDER_SECTION = 'dividerSection',
  INLINE_IMAGE_SECTION = 'inlineImageSection',
  CTA_BLOCK_SECTION = 'ctaBlockSection',
  INLINE_VIDEO_SECTION = 'inlineVideoSection',
  NEWSLETTER_SIGNUP = 'newsletterSignup',
  RELATED_ARTICLE_SECTION = 'relatedArticlesSection',
  LINK_BLOCK_SECTION = 'linkBlockSection',
  OPT_IN_SECTION = 'optInSection',
  VERTICAL_MEDIA_AND_CONTENT = 'verticalMediaAndContent',
  LOGO_AND_CONTENT = 'logoAndContent',
  TABLE_SECTION = 'tableSection',
  AWARDS_SECTION = 'awardsSection',
  HOW_IT_WORKS = 'howItWorks',
  CARMA_BUSINESS_PARTNERS = 'carmaBusinessPartners',
  COMPARISON_TABLE_SECTION = 'comparisonTableSection',
  CARMA_SPONSORSHIPS_SECTION = 'carmaSponsorshipsSection',
  MARKETING_BANNER = 'marketingBanner',
  GENERIC_HERO = 'genericHero',
  RECOMMENDED_CARS_CAROUSEL = 'recommendedCarsCarousel',
  ACTIVE_CAMPAIGN_FORM = 'activeCampaignForm',
}
