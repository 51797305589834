import classNames from 'classnames';
import { FC } from 'react';
import classes from './Icon.module.scss';
import { SvgIcon } from './SvgIcon.type';

export const Icon: FC<SvgIcon> = ({ children, className, ...props }) => (
  <svg {...props} aria-hidden="true" className={classNames('icon', classes.root, className)}>
    {children}
  </svg>
);
