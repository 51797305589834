import PQueue from 'p-queue';

import { getBaseUrl } from 'src/general/helpers/getBaseUrl';

import { carmaCsrAuthClient } from 'src/services/instance/carmaCsrAuthClient';
import { WatchlistItemRequest, WatchlistItemResponse, WatchlistNotifications } from './WatchlistApi.types';

export const apiWatchlistQueue = new PQueue({ concurrency: 1 });

export const getWatchlistItems = (): Promise<WatchlistItemResponse[]> =>
  carmaCsrAuthClient.get<WatchlistItemResponse[]>(`me/watch-list-items`).then((body) => body.json());

export const getWatchlistNotifications = (): Promise<WatchlistNotifications> =>
  carmaCsrAuthClient.get<WatchlistNotifications>(`me/watch-list-notifications`).then((body) => body.json());

export const updateWatchlistNotifications = (payload: WatchlistNotifications): Promise<WatchlistNotifications> =>
  carmaCsrAuthClient
    .patch<WatchlistNotifications>(`me/watch-list-notifications`, { json: payload })
    .then((body) => body.json());

export const addWatchlistItem = (payload: WatchlistItemRequest) =>
  apiWatchlistQueue.add(async (): Promise<WatchlistItemResponse[]> => {
    const res = await carmaCsrAuthClient.post<WatchlistItemResponse[]>(`me/watch-list-items`, { json: payload });
    const json = await res.json();

    await refreshUserWatchlist();

    return json;
  });

export const removeWatchlistItem = (sku: string) =>
  apiWatchlistQueue.add(async (): Promise<WatchlistItemResponse[]> => {
    const result = carmaCsrAuthClient
      .delete<WatchlistItemResponse[]>(`me/watch-list-items/${sku}`)
      .then((body) => body.json());

    await refreshUserWatchlist();

    return result;
  });

const refreshUserWatchlist = async () => {
  await fetch(
    new URL(`/api/cache/watchlist`, typeof window !== 'undefined' ? window.location.toString() : getBaseUrl()),
    {
      method: 'POST',
      cache: 'no-cache',
    },
  );
};
