import ky, { HTTPError } from 'ky';
import type { NormalizedOptions, Options } from 'ky/distribution/types/options';
import Cookies from 'universal-cookie';
import { errorCodeMap } from './errorCodeMap';

import { COOKIE_USER_TOKEN } from 'src/constants';

const cookies = new Cookies();

// This client is for data fetching from client

const opts: Options = {
  prefixUrl: `${process.env.REACT_APP_AUTH_BASE_API_URL}/`,
  timeout: 15000,
  hooks: {
    beforeRequest: [
      async (request) => {
        const token = cookies.get(COOKIE_USER_TOKEN);
        if (!token) {
          throw new HTTPError(new Response(undefined, { status: 401 }), request, {} as NormalizedOptions);
        }
        request.headers.set('Authorization', `Bearer ${token}`);
      },
    ],
    beforeError: [
      async (error) => {
        // Log errors in development
        if (process.env.DEBUG) {
          console.error(`Error ${error.response.status} for ${error.request.method} "${error.request.url}"`);
          try {
            const body = await error.response.json();
            console.error('Received: ', body);
          } catch (_e) {
            // Nothing to do
          }
        }
        return error;
      },
      async (error) => {
        const { response } = error;
        // Try to parse the response body to return the error message from the server
        if (response?.body) {
          try {
            const body: any = await response.json();
            console.error('Error received from the server', body);
            if ('message' in body && typeof body.message === 'string' && body.message.length) {
              error.message = body.message;
            }
            if ('code' in body) {
              // Handle error code mapping
              const errorCode = `${body.code}`;
              if (errorCodeMap.has(errorCode)) {
                error.message = errorCodeMap.get(errorCode)!;
              }
            }
          } catch (_e) {
            // Nothing to do
          }
        }

        return error;
      },
    ],
  },
};

/**
 * Note: only to be used in server components
 */
export const carmaCsrAuthClient = ky.create({
  ...opts,
  headers: {
    'Content-Type': 'application/json',
  },
});
