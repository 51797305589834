'use client';

import { FC } from 'react';
import { plpFilterOptionsFetcher } from 'src/plp/components/PlpFilters/plpFilterOptionsFetcher';
import useSWRImmutable from 'swr/immutable';
import { VehicleCarousel } from './VehicleCarousel';
import { VehicleCarouselProps } from './VehicleCarousel.type';

export const VehicleCarouselLegacy: FC<VehicleCarouselProps> = (props) => {
  const { data } = useSWRImmutable('vehicle-filters-immutable', () =>
    plpFilterOptionsFetcher({ includeMakesWithNoResult: false }),
  );

  if (!data) {
    return null;
  }

  return <VehicleCarousel {...props} filterData={data.filterData} />;
};
