import { captureMessage } from '@sentry/nextjs';
import { cloneDeep, throttle } from 'lodash';
import Cookies from 'universal-cookie';

import { COOKIE_BROWSER_ID, COOKIE_USER_ID } from 'src/constants';

/**
 * Carma tracker designed to send events back to our CDP
 * Uses the browser Beacon API in combination of throttle to ensure reduced amount of API calls
 */

/**
 * Type used to report events within the Web UI codebase
 */
export type CarmaTrackingEvent = Prettify<
  | {
      // Type for a navigation event
      event: 'navigation';
    }
  | {
      event: 'form_submission';
      metadata: {
        email: string;
        phone?: string;
        component_name: string;
      };
    }
>;

/**
 * Enriched type used to send events to our CDP
 */
type CarmaApiTrackingEvent = {
  // `event` and `metadata` types should overlap with `CarmaTrackingEvent`
  event: string;
  metadata?: Object;
  origin_url: string;
  timestamp: string;
  browser_id: string;
  user_id?: string;
};

const cookies = new Cookies();
const beaconThrottle = 5000;

/**
 * List of all the events
 */
const events: CarmaApiTrackingEvent[] = [];
/**
 * The list events queued to send to our CDP
 */
const eventsQueue: CarmaApiTrackingEvent[] = [];

export const getCarmaTrackingEvents = () => cloneDeep(events);
export const getCarmaTrackingEventsQueue = () => cloneDeep(eventsQueue);

export const initCarmaEventTracker = () => {
  if (typeof document !== 'undefined') {
    document.addEventListener('visibilitychange', handlePageUnload);
  }
};

export const removeCarmaEventTracker = () => {
  // Process the queue before unloading our tracker
  processQueue();
  if (typeof document !== 'undefined') {
    document.removeEventListener('visibilitychange', handlePageUnload);
  }
};

export const pushCarmaEvent = (data: CarmaTrackingEvent) => {
  const event = enrichCarmaEvent(data);
  if (event) {
    events.push(event);
    eventsQueue.push(event);
  }
  throttledProcessQueue();
};

/**
 *  Method in charge of sending all the pending events to our CDP
 */
const processQueue = (): void => {
  if (typeof navigator?.sendBeacon !== 'function' || eventsQueue.length === 0) {
    return;
  }

  const formData = new FormData();
  formData.append('events', JSON.stringify(eventsQueue));
  try {
    navigator.sendBeacon(`${process.env.REACT_APP_BIZ_API_URL}/carma-data`, formData);
  } catch (error) {
    captureMessage(`Error sending beacon with: "${error}"`, 'warning');
  }
  // Empty the queue
  eventsQueue.length = 0;
};

const handlePageUnload = () => {
  if (typeof document !== 'undefined' && document.visibilityState === 'hidden') {
    processQueue();
  }
};

const throttledProcessQueue = throttle(processQueue, beaconThrottle);

const enrichCarmaEvent = (data: CarmaTrackingEvent): CarmaApiTrackingEvent | undefined => {
  if (typeof window === 'undefined') {
    return undefined;
  }
  const origin_url = window.location.toString();
  const timestamp = new Date().toISOString();
  const browser_id = cookies.get(COOKIE_BROWSER_ID) ?? 'not_set';
  const user_id = cookies.get(COOKIE_USER_ID) ?? undefined;
  return {
    ...data,
    origin_url,
    timestamp,
    browser_id,
    user_id,
  };
};
