import { AnimatePresence, HTMLMotionProps, m } from 'framer-motion';
import { FC, useRef } from 'react';
import { LazyMotionWrapper } from '../components/LazyMotionWrapper/LazyMotionWrapper';

type Props = HTMLMotionProps<'div'> & {
  isVisible: boolean;
};
export const Transition: FC<Props> = ({ children, className, variants, isVisible, initial = true, ...props }) => {
  const nodeRef = useRef(null);

  return (
    <LazyMotionWrapper>
      <AnimatePresence mode="wait" initial={typeof initial === 'boolean' ? initial : undefined}>
        {isVisible && (
          <m.div
            {...props}
            variants={variants}
            initial="initial"
            animate="animate"
            exit="exit"
            ref={nodeRef}
            className={className}
          >
            {children}
          </m.div>
        )}
      </AnimatePresence>
    </LazyMotionWrapper>
  );
};
