export const errorCodeMap = new Map<string, string>([
  ['500', 'Something went wrong on our side, please try again.'],
  ['600', 'Please enter a valid email address.'],
  ['601', 'Please enter your current password.'],
  ['602', "Please enter a new password that's different to your current one."],
  ['603', 'Wrong password. Try again or click Forgot password to reset it.'],
  ['604', "Please enter a new mobile number that's different to your current one."],
  ['605', 'Please enter a valid Australian mobile number.'],
  ['700', 'An error occurred while updating your account. Please try again.'],
  ['701', "We couldn't find an account with those details. Try again or create an account."],
  ['702', 'An account with this email address already exists. Try again or login.'],
  ['703', 'Your session has expired, please log back in.'],
  ['704', 'An error occurred while updating your password. Please try again.'],
  ['705', 'An account with this mobile number already exists. Try again or login.'],
  ['706', 'An error occurred while updating your mobile number. Please try again.'],
  ['707', 'Something went wrong on our side, please try updating the phone number again.'],
  ['708', 'Your account is not active. Please check your email to activate the account.'],
  ['800', 'We could not send the verification code, please check the number and try again.'],
  ['801', 'This verification code is invalid. Please try again or go back to resend a new code.'],
]);
