import classNames from 'classnames';
import { ComponentProps, FC } from 'react';

import { isEmpty } from 'lodash';
import { Image } from 'src/general/components/Image/Image';
import { MaskedImage } from 'src/hygiene/components/MaskedImage/MaskedImage';
import { ValueProps } from 'src/hygiene/components/ValueProps/ValueProps';
import { FinanceHeroCalculator } from '../../FinanceCalculator/FinanceHeroCalculator/FinanceHeroCalculator';
import { FlexibleSection } from '../flexibleContent.type';
import { getSectionClasses } from '../getSectionClasses.helper';
import styles from './FinanceHeroBanner.module.scss';
import { FinanceHeroBannerForm } from './FinanceHeroBannerForm';

interface Props extends FlexibleSection {
  sectionData: {
    title: string;
    subtitle: string;
    lenderIntro: string;
    lenders: {
      icon: string;
    }[];
    note: string;
    termOfLoan: {
      min: number;
      max: number;
    };
    interestRateRange: {
      min: number;
      max: number;
    };
    buttonLabel: string;
    imgUrl: string;
    valueProps?: {
      imageUrl: string;
      title: string;
      description: string;
    }[];
  };
  microcopy: ComponentProps<typeof FinanceHeroCalculator>['microcopy'] & {
    errorMessage: string;
  };
}

export const FinanceHeroBanner: FC<Props> = ({ sectionData, microcopy, sectionBackground }) => (
  <section className={classNames(styles.root, getSectionClasses(sectionBackground), 'header-transparent')}>
    <header className={styles.header}>
      <h1 className="c-fs-d1">{sectionData.title}</h1>
      {!isEmpty(sectionData.subtitle) && (
        <p className={classNames(styles.subTitle, 'c-fs-body2', 'c-fw-normal')}>{sectionData.subtitle}</p>
      )}
    </header>
    <div className={styles.imageWrapper}>
      <MaskedImage
        className={styles.image}
        maskPosition="top"
        image={sectionData.imgUrl}
        alt=""
        width={612}
        height={460}
        priority
      />
      <div className={styles.lenderContainer}>
        <div className={styles.lenderIntroContainer}>
          <p className="c-fs-body2">{sectionData.lenderIntro}</p>
        </div>
        {sectionData.lenders.map((item) => (
          <Image key={item.icon} src={item.icon} className={styles.lander} alt="" width={92} height={33} />
        ))}
      </div>
    </div>
    <div className={styles.form}>
      <FinanceHeroBannerForm
        buttonLabel={sectionData.buttonLabel}
        interestRateRange={sectionData.interestRateRange}
        termOfLoan={sectionData.termOfLoan}
        microcopy={microcopy}
      />
    </div>
    <footer className={styles.footer}>
      {!isEmpty(sectionData.note) && (
        <div className={styles.note}>
          <p className="c-fs-caption2">{sectionData.note}</p>
        </div>
      )}
      <ValueProps valueProps={sectionData.valueProps} />
    </footer>
  </section>
);
