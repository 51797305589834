'use client';

import NextImage from 'next/image';
import { ComponentProps, FC } from 'react';

import { encodeImageSrc, getCarmaGenericImageLoader } from 'src/general/helpers/carmaGenericImageLoader';

type Props = Omit<ComponentProps<typeof NextImage>, 'loader'> & { src: string };

/**
 * Wrapper around NextJS Image component to allow dynamic selection of the image loader
 */
export const Image: FC<Props> = ({ ...props }) => {
  // Encode spaces in the src URL
  const encodedSrc = typeof props.src === 'string' ? encodeImageSrc(props.src) : props.src;
  const loader = getCarmaGenericImageLoader(encodedSrc);

  return <NextImage {...props} src={encodedSrc} loader={loader} />;
};
