import { produce } from 'immer';
import { isEmpty, isNil } from 'lodash';
import Cookies from 'universal-cookie';

import { COOKIE_USER_ID, COOKIE_USER_TOKEN } from 'src/constants';
import { ProfileApiResponse } from 'src/data/ProfileApi/ProfileApi.types';
import { getCookieExpiryAuth, getCookieExpiryTracking } from 'src/general/helpers/getCookieExpiry';
import { OrdersResponse } from 'src/types/CataloguePage.types';
import { pushToDataLayer } from 'src/utils/pushToDataLayer';

import { carmaClientLegacy } from '../services/instance/carmaClientLegacy';
import { storeFactory } from './storeFactory';

export interface UserAuthData {
  id: string;
  accessToken: string;
}

interface AuthStoreStateData {
  userAuthData: UserAuthData | null;
  userAuthDataLoading: boolean;
  userDataLoading: boolean;
  userData: ProfileApiResponse | null;
  isUserLoggedIn: boolean;
  userOrdersLoading: boolean;
  userOrders: OrdersResponse[];
}

interface AuthStoreStateFunctions {
  setUserAuthData: (userAuthData: UserAuthData | null, setState?: boolean) => void;
  setUserDataLoading: () => void;
  setUserData: (userData: ProfileApiResponse | null) => void;
  setUserOrdersLoading: () => void;
  setUserOrders: (orders: OrdersResponse[]) => void;
}

const cookies = new Cookies();

const {
  store: authStore,
  StoreProvider: AuthStoreProvider,
  useCreateStore: useCreateAuthStore,
} = storeFactory<AuthStoreStateData, AuthStoreStateFunctions>(
  () => {
    return {
      userAuthData: null,
      userAuthDataLoading: true,
      userDataLoading: false,
      userData: null,
      isUserLoggedIn: false,
      userOrdersLoading: false,
      userOrders: [],
    };
  },
  (set) => ({
    setUserAuthData: (userAuthData: UserAuthData | null, setState: boolean = true) => {
      if (userAuthData) {
        carmaClientLegacy.defaults.headers.common.Authorization = `Bearer ${userAuthData.accessToken}`;

        cookies.set(COOKIE_USER_ID, userAuthData.id, {
          path: '/',
          secure: process.env.NODE_ENV === 'production',
          expires: getCookieExpiryTracking(),
          sameSite: 'strict',
        });
        cookies.set(COOKIE_USER_TOKEN, userAuthData.accessToken, {
          path: '/',
          secure: process.env.NODE_ENV === 'production',
          expires: getCookieExpiryAuth(),
          sameSite: 'strict',
        });
        // Push to data layer
        pushToDataLayer({
          user_id: userAuthData.id,
        });
      }
      if (setState) {
        set((state) =>
          produce(state, (draft) => {
            draft.userAuthData = userAuthData;
            draft.userAuthDataLoading = false;
            draft.isUserLoggedIn = !isNil(userAuthData);
          }),
        );
      }
    },
    setUserDataLoading: () => {
      set((state) =>
        produce(state, (draft) => {
          draft.userDataLoading = true;
        }),
      );
    },
    setUserData: (userData: ProfileApiResponse | null) => {
      set((state) =>
        produce(state, (draft) => {
          draft.userData = userData;
          draft.userDataLoading = false;
        }),
      );
    },
    setUserOrdersLoading: () => {
      set((state) =>
        produce(state, (draft) => {
          draft.userOrdersLoading = true;
        }),
      );
    },
    setUserOrders: (orders: OrdersResponse[]) => {
      set((state) =>
        produce(state, (draft) => {
          draft.userOrdersLoading = false;
          draft.userOrders = orders;
        }),
      );
    },
  }),
);

export const getUserAuthDataFromCookies = (): UserAuthData | null => {
  const id = cookies.get(COOKIE_USER_ID);
  const accessToken = cookies.get(COOKIE_USER_TOKEN);

  return !isEmpty(id) && !isEmpty(accessToken) ? { id, accessToken } : null;
};

export { authStore, AuthStoreProvider, useCreateAuthStore };
