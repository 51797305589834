export const slugify = (original: string, separator: string = '-'): string => {
  // Turn the string to lowercase
  return original
    .toLocaleLowerCase()
    .trim()
    .normalize('NFD') // Normalize to decompose Unicode characters
    .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
    .replace(/\s+/g, separator) // Replace spaces with separator
    .replace(/[^\w\s-]/g, '') // Remove non-word characters (except separator)
    .replace(new RegExp(`${separator}+`, 'g'), separator) // Replace multiple separators with single one
    .replace(new RegExp(`^${separator}+`, 'g'), '') // Remove leading separator
    .replace(new RegExp(`${separator}$`, 'g'), ''); // Remove trailing separator
};
