import { Form, Formik } from 'formik';
import { ComponentProps, FC, ReactNode, useEffect, useMemo, useState } from 'react';

import { FinanceEnquiryForm } from 'src/pdp/components/FinanceEnquiryForm/FinanceEnquiryForm';
import { DataLayerFinanceCalculatorEvent, pushToDataLayerFinanceCalculator } from 'src/utils/pushToDataLayer';

import { Tabs } from 'src/general/components/Tabs/Tabs';
import { calculatePrincipal, calculateWeeklyPayment } from '../FinanceCalculator.helper';
import {
  FinanceCalculatorProps,
  FinanceEnquiryFormValidation,
  FinanceEnquiryFormValues,
} from '../FinanceCalculator.type';
import { MyRepayment } from '../components/MyRepayment/MyRepayment';
import { WhatCanIAfford } from '../components/WhatCanIAfford/WhatCanIAfford';
import styles from './FinanceHeroCalculator.module.scss';

const enum TabType {
  WHAT_CAN_I_AFFORD,
  MY_REPAYMENTS,
}

interface TabPanelProps {
  children?: ReactNode;
  type: TabType;
  tabSelected: TabType;
}

/*
  Tab panel configurations
*/
const TabPanel: FC<TabPanelProps> = ({ children, tabSelected, type, ...other }) => (
  <div
    role="tabpanel"
    hidden={tabSelected !== type}
    id={`simple-tabpanel-${type}`}
    aria-labelledby={`simple-tab-${type}`}
    {...other}
  >
    {tabSelected === type && children}
  </div>
);

interface FinanceHeroCalculatorProps extends FinanceCalculatorProps {
  microcopy: {
    whatCanIAfford: ComponentProps<typeof WhatCanIAfford>['microcopy'];
    repayment: ComponentProps<typeof MyRepayment>['microcopy'];
    enquiryForm: ComponentProps<typeof FinanceEnquiryForm>['microcopy'];
  };
}

export const FinanceHeroCalculator: FC<FinanceHeroCalculatorProps> = ({
  interestRateRange,
  loanTerms,
  onSubmit,
  microcopy,
}) => {
  const [tabSelected, setTabSelected] = useState<TabType>(TabType.WHAT_CAN_I_AFFORD);

  const onChangeTab = (newValue: TabType) => {
    if (tabSelected !== newValue) {
      pushToDataLayerFinanceCalculator(DataLayerFinanceCalculatorEvent.finance_calculator_switch_tab, {
        tab: newValue === TabType.WHAT_CAN_I_AFFORD ? 'WHAT_CAN_I_AFFORD' : 'MY_REPAYMENTS',
      });
    }
    setTabSelected(newValue);
  };

  const loanTermsArray = useMemo(
    () => Array.from({ length: loanTerms.max - loanTerms.min + 1 }, (_value, index) => loanTerms.min + index),
    [loanTerms],
  );

  const onSubmitForm = async (data: FinanceEnquiryFormValues) => {
    onSubmit(data);
  };

  return (
    <div className={styles.root}>
      <Tabs
        tabsId="finance"
        tabs={[
          {
            id: 'WHAT_CAN_I_AFFORD',
            title: 'What can I afford?',
            content: null,
            onClick: () => {
              onChangeTab(TabType.WHAT_CAN_I_AFFORD);
            },
          },
          {
            id: 'MY_REPAYMENTS',
            title: 'My repayments',
            content: null,
            onClick: () => {
              onChangeTab(TabType.MY_REPAYMENTS);
            },
          },
        ]}
      />
      <Formik
        initialValues={{
          weeklyPayment: 150,
          depositAmount: 0,
          interestRate: 10.02,
          purchasePrice: 30000,
          termOfLoan: 5,
          message: '',
          firstName: '',
          lastName: '',
          emailAddress: '',
          phoneNumber: '',
        }}
        onSubmit={onSubmitForm}
        validationSchema={FinanceEnquiryFormValidation}
      >
        {({ values, setFieldValue }) => {
          const { weeklyPayment, depositAmount, interestRate, termOfLoan, purchasePrice } = values;
          useEffect(() => {
            // set the value of textC, based on textA and textB
            if (
              `${weeklyPayment}`.trim() !== '' &&
              `${depositAmount}`.trim() !== '' &&
              `${interestRate}`.trim() !== '' &&
              `${termOfLoan}`.trim() !== '' &&
              tabSelected === TabType.WHAT_CAN_I_AFFORD
            ) {
              setFieldValue(
                'purchasePrice',
                Math.round(
                  calculatePrincipal(weeklyPayment, interestRate, termOfLoan, parseInt(`${depositAmount}`, 10)),
                ),
              );
            }
          }, [weeklyPayment, depositAmount, interestRate, termOfLoan, setFieldValue, tabSelected]);

          useEffect(() => {
            // set the value of textC, based on textA and textB
            if (
              `${purchasePrice}`.trim() !== '' &&
              `${depositAmount}`.trim() !== '' &&
              `${interestRate}`.trim() !== '' &&
              `${termOfLoan}`.trim() !== '' &&
              tabSelected === TabType.MY_REPAYMENTS
            ) {
              setFieldValue(
                'weeklyPayment',
                Math.round(calculateWeeklyPayment(purchasePrice, interestRate, termOfLoan, depositAmount)),
              );
            }
          }, [purchasePrice, depositAmount, interestRate, termOfLoan, setFieldValue, tabSelected]);

          return (
            <Form className={styles.form}>
              <TabPanel tabSelected={tabSelected} type={TabType.WHAT_CAN_I_AFFORD}>
                <WhatCanIAfford
                  loanTermsArray={loanTermsArray}
                  interestRateRange={interestRateRange}
                  values={values}
                  setFieldValue={setFieldValue}
                  microcopy={microcopy.whatCanIAfford}
                />
              </TabPanel>
              <TabPanel tabSelected={tabSelected} type={TabType.MY_REPAYMENTS}>
                <MyRepayment
                  loanTermsArray={loanTermsArray}
                  interestRateRange={interestRateRange}
                  values={values}
                  setFieldValue={setFieldValue}
                  microcopy={microcopy.repayment}
                />
              </TabPanel>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
