import classNames from 'classnames';
import Image from 'next/image';
import { ComponentProps, FC } from 'react';

import { CTAButton } from 'src/general/components/Button/CTAButton';
import carmaGenericImageLoader from 'src/general/helpers/carmaGenericImageLoader';

import { FlexibleSection } from '../flexibleContent.type';
import { getSectionClasses } from '../getSectionClasses.helper';
import styles from './MarketingBanner.module.scss';

interface Props extends FlexibleSection {
  sectionData: {
    title: string;
    description: string;
    cta: ComponentProps<typeof CTAButton>[];
    mobileImage: string;
    desktopImage: string;
    tabletImage: string;
  };
}

export const MarketingBanner: FC<Props> = ({
  sectionData: { cta, desktopImage, tabletImage, title, mobileImage, description },
  sectionBackground,
}) => {
  return (
    <section className={classNames(styles.root, getSectionClasses(sectionBackground))}>
      <div className={styles.contentWrapper}>
        <div className={styles.imageContainer}>
          <Image
            src={mobileImage}
            width={343}
            height={151}
            className={classNames(styles.image, styles.mobileImage)}
            alt=""
            loader={carmaGenericImageLoader}
          />
          <Image
            src={tabletImage}
            width={392}
            height={320}
            className={classNames(styles.image, styles.tabletImage)}
            alt=""
            loader={carmaGenericImageLoader}
          />
          <Image
            src={desktopImage}
            width={640}
            height={240}
            className={classNames(styles.image, styles.desktopImage)}
            alt=""
            loader={carmaGenericImageLoader}
          />
        </div>
        <div className={styles.content}>
          <div className={styles.textWrapper}>
            <h2 className={classNames(styles.title, 'c-fw-bold', 'c-fs-h4')}>{title}</h2>
            <div className={classNames(styles.description, 'c-fw-normal')}>{description}</div>
          </div>
          {cta.map((item) => (
            <CTAButton size="medium" className={styles.cta} key={item.label?.toString()} {...item} />
          ))}
        </div>
      </div>
    </section>
  );
};
