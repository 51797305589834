'use client';

import {
  FloatingFocusManager,
  FloatingPortal,
  autoUpdate,
  flip,
  offset,
  size,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useTransitionStatus,
} from '@floating-ui/react';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
import {
  ChangeEvent,
  FC,
  FormEvent,
  KeyboardEvent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
  useTransition,
} from 'react';
import { useDebounce, useLocalStorage } from 'react-use';
import { shallow } from 'zustand/shallow';

import { IconCircleCrossFilled } from 'src/general/Icons/IconCircleCrossFilled';
import { IconSearch } from 'src/general/Icons/IconSearch';
import { IndexItem } from 'src/general/helpers/fuzzySearchHelper';
import { lockScroll, unlockScroll } from 'src/general/helpers/scrollLock';
import { FilterListPayload } from 'src/types/CarFilters.types';
import { pushToDataLayer } from 'src/utils/pushToDataLayer';

import { recentFilterSearchStore } from 'src/stores/recentFilterSearchStore';
import { ButtonIcon } from '../Button/ButtonIcon';
import { ConditionalWrapper } from '../ConditionalWrapper/ConditionalWrapper';
import { hasSearchBoxDisplayed } from '../HeaderMenu/HeaderMenu.helpers';
import { LoadingIndicator } from '../LoadingIndicator/LoadingIndicator';
import { KEYWORD_SEARCH_PLACEHOLDER } from './KeywordSearch.constants';
import { getDefaultSearchRecent, getDefaultSearchUrl } from './KeywordSearch.helper';
import styles from './KeywordSearch.module.scss';
import { ListRef, SearchIndexes } from './KeywordSearch.types';

interface Props {
  className?: string;
  vehicleFilters?: FilterListPayload;
  isMobile?: boolean;
}

const SearchPopper = dynamic(() => import('./SearchPopper').then(({ SearchPopper }) => SearchPopper), {
  ssr: false,
  loading: () => <LoadingIndicator />,
});

const MAX_RECENT_SEARCHES = 5;

const initialSearchIndexes: SearchIndexes = {
  recentSearches: 0,
  keywordSearches: 0,
  suggestedCars: 0,
  recentFilterSearches: 0,
};

export const KeywordSearch: FC<Props> = ({ className, vehicleFilters, isMobile }) => {
  const pathname = usePathname();
  const displaySearchBox = hasSearchBoxDisplayed(pathname);
  const [, startTransition] = useTransition();
  const [load, setLoad] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [searchIndexes, setSearchIndexes] = useState<SearchIndexes>({ ...initialSearchIndexes });
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom-start',
    transform: false,
    middleware: [
      flip({ padding: 8 }),
      offset(8),
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${availableHeight}px`,
          });
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const listRef: ListRef = useRef<Array<HTMLElement | null>>([]);

  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: true,
  });

  const dismiss = useDismiss(context, {
    escapeKey: false,
    outsidePress: !isMobile,
  });

  const { isMounted, status } = useTransitionStatus(context, { duration: 200 });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([listNav, dismiss]);

  const [firstSearchResult, setFirstSearchResult] = useState<IndexItem | null>(null);
  const [recentSearches, setRecentSearches] = useLocalStorage<IndexItem[]>('recentSearches', []);

  const { recentFilterSearches, removeFilterSearch } = recentFilterSearchStore(
    (state) => ({
      recentFilterSearches: state.recentFilterSearches,
      removeFilterSearch: state.removeFilterSearch,
    }),
    shallow,
  );

  const addRecentSearch = useCallback(
    (search: IndexItem) => {
      const newRecentSearches = recentSearches ? [...recentSearches] : [];
      // Deduplicate results if needed
      const duplicateIndex = newRecentSearches.findIndex((item) => item.key === search.key);
      if (duplicateIndex >= 0) {
        newRecentSearches.splice(duplicateIndex, 1);
      }
      // Add the new search at the beginning
      newRecentSearches.unshift(search);
      // Cap the recent searches to MAX_RECENT_SEARCHES
      newRecentSearches.length = Math.min(newRecentSearches.length, MAX_RECENT_SEARCHES);
      setRecentSearches(newRecentSearches);
    },
    [recentSearches],
  );

  const removeRecentSearch = useCallback(
    (index: number) => {
      const newRecentSearches = recentSearches ? [...recentSearches] : [];
      newRecentSearches.splice(index, 1);
      setRecentSearches(newRecentSearches);
    },
    [recentSearches],
  );

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  }, []);

  const handleFocus = useCallback(() => {
    setIsOpen(true);
    startTransition(() => {
      setLoad(true);
    });
  }, []);

  const handleClear = useCallback(() => {
    setSearchTerm('');
    // Refocus on the input
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleSubmit = useCallback(
    (event: KeyboardEvent<HTMLInputElement | HTMLButtonElement> | FormEvent<HTMLFormElement>) => {
      if (activeIndex !== null && listRef.current && listRef.current[activeIndex]) {
        event.preventDefault();
        setSearchTerm('');
        listRef.current[activeIndex]?.click();
      } else {
        const url = getDefaultSearchUrl(searchTerm);
        if (searchTerm) {
          addRecentSearch(getDefaultSearchRecent(searchTerm));
        }
        window.location.href = url;
        setIsOpen(false);
      }
    },
    [activeIndex, searchTerm, addRecentSearch, setIsOpen],
  );
  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement | HTMLButtonElement>) => {
      const targetIsResetButton = (event.target as HTMLButtonElement)?.type === 'reset';
      const isDeleteKey = event.key === 'Delete';
      const isEnterKey = event.key === 'Enter';
      const isEscapeKey = event.key === 'Escape';

      if (isEnterKey && !targetIsResetButton) {
        handleSubmit(event);
        return;
      }

      if (isEscapeKey) {
        if (searchTerm !== '') {
          event.preventDefault();
          setSearchTerm('');
        } else {
          setIsOpen(false);
        }
        return;
      }

      if (isDeleteKey && searchTerm === '' && activeIndex !== null) {
        event.preventDefault();
        const totalRecentSearches = (recentSearches?.length ?? 0) + (recentFilterSearches?.length ?? 0);

        if (recentSearches && activeIndex < recentSearches.length) {
          removeRecentSearch(activeIndex);
        } else if (recentFilterSearches && activeIndex < totalRecentSearches) {
          const filterSearchIndex = activeIndex - (recentSearches?.length ?? 0);
          removeFilterSearch(recentFilterSearches[filterSearchIndex]);
        }
      }
    },
    [
      handleSubmit,
      searchTerm,
      activeIndex,
      recentSearches,
      recentFilterSearches,
      removeRecentSearch,
      removeFilterSearch,
      setSearchTerm,
      setIsOpen,
    ],
  );

  const wrapperDesktop = useCallback((children: ReactNode) => <FloatingPortal>{children}</FloatingPortal>, []);
  const wrapperFocusDesktop = useCallback(
    (children: ReactNode) => (
      <FloatingFocusManager context={context} initialFocus={-1} visuallyHiddenDismiss>
        <>{children}</>
      </FloatingFocusManager>
    ),
    [context],
  );

  useDebounce(
    () => {
      if (searchTerm) {
        pushToDataLayer({
          event: 'search_attempt',
          value: searchTerm,
        });
      }
    },
    200,
    [searchTerm],
  );

  useEffect(() => {
    if (isOpen) {
      lockScroll();
    } else {
      unlockScroll();
      // Trigger an event to propagate to SearchWidget
      const eventOrigin = inputRef.current;
      const closeEvent = new Event('keywordSearchClose', { bubbles: true, cancelable: false });
      if (eventOrigin) {
        eventOrigin.dispatchEvent(closeEvent);
      }
    }

    return () => {
      unlockScroll();
    };
  }, [isOpen]);

  return (
    <form
      {...getReferenceProps({
        ref: refs.setReference,
        onKeyDown: handleKeyDown,
      })}
      className={classNames(styles.root, className, 'keywordSearch', {
        // Class hiding the search box in header bar when on page top (e.g. for homepage)
        keywordSearchHidden: !displaySearchBox,
        [styles.isMobile]: isMobile,
      })}
      action="/used-cars"
      onFocus={handleFocus}
      onSubmit={handleSubmit}
    >
      <input
        type="text"
        name="search"
        value={searchTerm}
        onChange={handleChange}
        aria-autocomplete="list"
        autoComplete="off"
        placeholder={KEYWORD_SEARCH_PLACEHOLDER}
        className={classNames(styles.input, 'keywordSearch-input')}
        data-testid="FUZZY-SEARCH-INPUT"
        ref={inputRef}
      />
      <div className={classNames(styles.controls, 'keywordSearch-controls')}>
        <ButtonIcon
          Icon={IconCircleCrossFilled}
          size="small"
          variant="tertiary"
          title="Clear"
          data-testid="FUZZY-SEARCH-CLEAR_TEXT_BTN"
          type="reset"
          onClick={handleClear}
          className={classNames(styles.clear, {
            [styles.active]: searchTerm.length > 0,
          })}
        />
        <span className={styles.spacer} />
        <ButtonIcon
          Icon={IconSearch}
          size="small"
          variant="tertiary"
          title="Search"
          data-testid="FUZZY-SEARCH-SEARCH_BTN"
          type="submit"
          disabled={searchTerm.length === 0}
        />
      </div>
      <ConditionalWrapper condition={!isMobile} wrapper={wrapperDesktop}>
        {isMounted && (
          <ConditionalWrapper condition={!isMobile} wrapper={wrapperFocusDesktop}>
            <div
              className={classNames(styles.popper, 'keywordSearch-popper')}
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
              data-status={status}
            >
              {load && vehicleFilters ? (
                <SearchPopper
                  {...{
                    value: searchTerm,
                    vehicleFilters,
                    searchIndexes,
                    setSearchIndexes,
                    activeIndex,
                    listRef,
                    getItemProps,
                    setOpen: setIsOpen,
                    firstSearchResult,
                    setFirstSearchResult,
                    recentSearches: recentSearches ?? [],
                    addRecentSearch,
                    removeRecentSearch,
                    recentFilterSearches: recentFilterSearches ?? [],
                    removeFilterSearch,
                  }}
                />
              ) : (
                <LoadingIndicator />
              )}
            </div>
          </ConditionalWrapper>
        )}
      </ConditionalWrapper>
    </form>
  );
};
