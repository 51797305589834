import { Input, InputAdornment, InputLabelProps, InputProps } from '@material-ui/core';
import classNames from 'classnames';
import { debounce, isNil } from 'lodash';
import dynamic from 'next/dynamic';
import { ChangeEvent, ComponentProps, FC, InputHTMLAttributes, ReactNode, useRef } from 'react';
import { IMaskInput } from 'react-imask';
import { ButtonIcon } from 'src/general/components/Button/ButtonIcon';
import { SvgIcon } from 'src/general/Icons/SvgIcon.type';
import classes from './FormikInput.module.scss';
import {
  FormikInputBaseProps,
  FormikInputPlaceholderProps,
  FormikInputTextProps,
  FormikInputWrapperChildProps,
} from './FormikInput.types';
import { FormikInputWrapper } from './FormikInputWrapper';

const FormikInputMask = dynamic(() => import('./FormikInputMask').then(({ FormikInputMask }) => FormikInputMask), {
  ssr: false,
});

interface FormikInputProps
  extends FormikInputTextProps,
    FormikInputPlaceholderProps,
    Pick<InputProps, 'type'>,
    Pick<InputHTMLAttributes<HTMLInputElement>, 'inputMode' | 'maxLength' | 'pattern'> {
  maskType?: 'mobile' | 'monthYearFormat' | 'abn';
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  multiline?: number | boolean;
  inputActionButton?: {
    Icon: FC<SvgIcon>;
    onClick: () => void;
  };
  hidden?: boolean;
  inputProps?: InputProps['inputProps'] & { min?: number; max?: number; step?: number };
  InputLabelProps?: InputLabelProps;
  InputProps?: InputProps;
  onTrackingEvent?: (values: any) => void;
}

/** @deprecated Please use InputFormik instead of this component if you're in NextJS App router */
export const FormikInput: FC<FormikInputProps> = ({
  className,
  labelOutside,
  hidden,
  multiline,
  InputLabelProps,
  replaceHelpTextWhenError,
  ...rest
}) => (
  <FormikInputWrapper
    className={classNames(className, {
      [classes.labelOutside]: labelOutside,
      [classes.hidden]: hidden,
    })}
    multiline={multiline}
    InputLabelProps={InputLabelProps}
    replaceHelpTextWhenError={replaceHelpTextWhenError}
    Component={FormikInputInner as any}
    testIdPrefix="FormikInput"
    {...rest}
  />
);

type FormikInputInnerProps = FormikInputWrapperChildProps<string> &
  Omit<FormikInputProps, keyof Omit<FormikInputBaseProps, 'disabled'> | 'size' | 'labelOutside' | 'InputLabelProps'>;

const FormikInputInner: FC<FormikInputInnerProps> = ({
  field: { name, value, onChange, onBlur },
  helpers,
  disabled,
  placeholder,
  type,
  inputMode,
  maxLength,
  pattern,
  iconLeft,
  iconRight,
  maskType,
  multiline,
  autoComplete,
  id,
  autoFocus,
  inputActionButton,
  inputProps,
  InputProps,
  onTrackingEvent,
}) => {
  const ref = useRef(
    debounce((value) => {
      onTrackingEvent?.(value);
    }, 500),
  );
  // eslint-disable-next-line no-undef-init
  let mask: ComponentProps<typeof IMaskInput>['mask'] | undefined = undefined;

  // mask can override some props
  if (maskType) {
    if (maskType === 'mobile') {
      type = 'tel';
      mask = '0000 000 000';
      inputMode = 'tel';
    } else if (maskType === 'monthYearFormat') {
      type = 'text';
      mask = '00/0000';
      inputMode = 'numeric';
    } else if (maskType === 'abn') {
      type = 'text';
      mask = '00 000 000 000';
      inputMode = 'numeric';
    }
  }

  const rows = typeof multiline === 'number' ? multiline : undefined;

  const onChangeDecimal = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const formattedValue = parseFloat(e.target.value || '0').toString();
    helpers.setValue(formattedValue);
    ref.current(formattedValue);
  };

  const onChangeText = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onChange(e);
    ref.current(e.target.value);
  };

  return (
    <>
      {/* Skipping mask input from tests due to problem with library when testing: https://stackoverflow.com/questions/60394967/how-to-unit-test-masked-input */}
      {mask && process.env.NODE_ENV !== 'test' ? (
        <FormikInputMask
          name={name}
          type={type}
          multiline={false}
          rows={rows}
          placeholder={placeholder}
          inputProps={{
            id,
            autoComplete,
            autoFocus,
            maxLength,
            inputMode,
            pattern,
          }}
          onBlur={onBlur}
          defaultValue={value}
          onAccept={(value: string) => helpers.setValue(value)}
          mask={mask}
          startAdornment={iconLeft && <InputAdornment position="start">{iconLeft}</InputAdornment>}
          endAdornment={
            iconRight && isNil(InputProps?.endAdornment) && <InputAdornment position="end">{iconRight}</InputAdornment>
          }
        />
      ) : (
        <Input
          {...InputProps}
          name={name}
          type={type}
          multiline={!!multiline}
          rows={rows}
          placeholder={placeholder}
          inputProps={{
            id,
            autoComplete,
            autoFocus,
            maxLength,
            inputMode,
            pattern,
            ...inputProps,
          }}
          onBlur={onBlur}
          onChange={type === 'decimal' ? onChangeDecimal : onChangeText}
          value={value}
          startAdornment={iconLeft && <InputAdornment position="start">{iconLeft}</InputAdornment>}
          endAdornment={
            iconRight && isNil(InputProps?.endAdornment) && <InputAdornment position="end">{iconRight}</InputAdornment>
          }
        />
      )}
      {maxLength && <p className={classes.helperText}>{`${value.length}/${maxLength}`}</p>}
      {inputActionButton && (
        <div className={classes.inputActionWrapper}>
          <ButtonIcon
            title="Toggle visibility"
            onClick={inputActionButton.onClick}
            Icon={inputActionButton.Icon}
            size="small"
            variant="tertiary"
            disabled={disabled}
            className={classes.inputAction}
          />
        </div>
      )}
    </>
  );
};
