import { ComponentProps, FC } from 'react';

import { Transition } from './Transition';

const TRANSITION_DURATION = 0.3;

const variants = {
  initial: {
    opacity: 0,
    transform: 'translate3d(100%, 0, 0)',
  },
  animate: {
    opacity: 1,
    transform: 'translate3d(0, 0, 0)',
    transition: {
      duration: TRANSITION_DURATION,
      ease: 'easeInOut',
    },
  },
  exit: {
    opacity: 0,
    transform: 'translate3d(100%, 0, 0)',
    transition: {
      duration: TRANSITION_DURATION,
      ease: 'easeInOut',
    },
  },
};

export const Slide: FC<ComponentProps<typeof Transition>> = (props) => <Transition {...props} variants={variants} />;
