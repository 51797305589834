import classNames from 'classnames';
import { FC } from 'react';
import { Image } from 'src/general/components/Image/Image';
import styles from './MaskedImage.module.scss';

type MaskedImageProps = {
  image: string;
  width: number;
  height: number;
  alt?: string;
  className?: string;
  maskPosition: 'top' | 'left';
  priority?: boolean;
};

export const MaskedImage: FC<MaskedImageProps> = ({ image, width, height, alt, className, maskPosition, priority }) => {
  return (
    <div
      className={classNames(styles.root, className, {
        [styles.maskTop]: maskPosition === 'top',
        [styles.maskLeft]: maskPosition === 'left',
      })}
      style={{ aspectRatio: `${width} / ${height}` }}
    >
      <div className={classNames(styles.content)}>
        <Image
          src={image}
          width={width}
          height={height}
          alt={alt ?? ''}
          className={classNames(styles.image)}
          priority={priority}
        />
      </div>
    </div>
  );
};
