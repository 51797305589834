'use client';

import { LazyMotion } from 'framer-motion';
import { FC, PropsWithChildren } from 'react';

export const LazyMotionWrapperFull: FC<PropsWithChildren> = ({ children }) => {
  return (
    <LazyMotion features={async () => (await import('./fullFramerMotionFeatures')).default}>{children}</LazyMotion>
  );
};
