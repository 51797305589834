import classNames from 'classnames';
import { ComponentProps, FC } from 'react';
import { SvgIcon } from 'src/general/Icons/SvgIcon.type';
import { Button } from './Button';
import classes from './Button.module.scss';

type Props = Prettify<
  Omit<
    ComponentProps<typeof Button>,
    'children' | 'styling' | ' startIcon' | 'endIcon' | 'title' | 'fullWidth' | 'ref'
  > & {
    styling?: 'square' | 'round';
    variant?: 'primary' | 'secondary' | 'tertiary';
    Icon: FC<SvgIcon>;
    /** Title of the button, used for accessibility purposes */
    title: string;
  }
>;

export const ButtonIcon: FC<Props> = ({ Icon, styling = 'square', className, ...props }) => (
  <Button {...(props as any)} className={classNames(className, classes.buttonIcon, `button--${styling}`)}>
    <Icon />
  </Button>
);
