import { FC } from 'react';

import { IconDeliveryCar } from 'src/general/Icons/IconDeliveryCar';
import { Button } from 'src/general/components/Button/Button';
import { Image } from 'src/general/components/Image/Image';
import { Ratings } from 'src/hygiene/components/flexible-content/Ratings/Ratings';
import { REVIEW_SOURCE } from 'src/hygiene/sections/flexible-content/CustomerReviews/CustomerReviews.types';

import styles from './ReviewCard.module.scss';

const MAX_WORDS_BEFORE_TRUNCATE = 75;

interface ReviewCardProps {
  rating: number;
  review: string;
  customerName: string;
  car: string;
  source: REVIEW_SOURCE;
  link: string;
  date?: string;
}

export const ReviewCard: FC<ReviewCardProps> = ({ rating, review, customerName, source, date, link, car }) => {
  const trimmedContent = review.trim();
  const words = trimmedContent.split(' ');

  return (
    <div className={styles.root}>
      <div className={styles.rating}>
        <Ratings className={styles.stars} rating={rating} />
        <div className={styles.imageContainer}>
          <Image
            src={
              source === REVIEW_SOURCE.GOOGLE
                ? '/images/components/ReviewCard/google.svg'
                : '/images/components/ReviewCard/productReview.svg'
            }
            width={144}
            height={24}
            alt=""
          />
        </div>
      </div>
      <div>
        {words.length > MAX_WORDS_BEFORE_TRUNCATE ? (
          <>
            {`${words.slice(0, MAX_WORDS_BEFORE_TRUNCATE + 1).join(' ')}... `}
            <Button href={link} size="small" variant="link" target="_blank" rel="noopener noreferrer">
              Read more
            </Button>
          </>
        ) : (
          trimmedContent
        )}
      </div>
      <div className={styles.info}>
        <div className={styles.name}>{customerName}</div>
        <div className={styles.date}>{date}</div>
        <div className={styles.carType}>
          <IconDeliveryCar />
          {car}
        </div>
      </div>
    </div>
  );
};
