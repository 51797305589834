import PQueue from 'p-queue';

import { carmaCsrAuthClient } from 'src/services/instance/carmaCsrAuthClient';
import { getOr } from 'src/utils/getOr';

import { CheckoutApiPayload, CheckoutApiResponse, CheckoutApiUpdateVehiclePayload } from './CheckoutApi.types';

const apiCreateCheckoutQueue = new PQueue({ concurrency: 1 });

/**
 * Retrieve the current user cart
 */
export const getCheckouts = () =>
  apiCreateCheckoutQueue.add(() => carmaCsrAuthClient.get(`me/checkouts`).json<CheckoutApiResponse[]>());

/**
 * Create a cart
 * @param sku The vehicle SKU
 */
export const createCheckout = (sku: string) =>
  apiCreateCheckoutQueue.add(() =>
    carmaCsrAuthClient
      .post<CheckoutApiPayload>(`me/checkouts`, {
        json: {
          vehicle: {
            sku,
          },
        },
      })
      .json(),
  );

/**
 * Replace the car in the cart
 * @param cartId The cart ID
 * @param sku The vehicle SKU
 */
export const switchVehicleCheckout = (cartId: string, sku: string) =>
  carmaCsrAuthClient
    .patch<CheckoutApiUpdateVehiclePayload>(`me/checkouts/${cartId}/vehicle`, {
      json: {
        sku,
      },
    })
    .json();

/**
 * Update a cart
 * @param cartId The cart ID
 * @param payload The data to pass to update the cart
 */
export const updateCheckout = (cartId: string, payload: CheckoutApiPayload) =>
  carmaCsrAuthClient.patch<CheckoutApiResponse>(`me/checkouts/${cartId}`, { json: payload }).json();

export const handleUpdateCheckoutError = (error: any): string => {
  let errorMessage = 'An unknown error happened, please try again later';
  if (error.response) {
    errorMessage = getOr(error.response.data, 'detail[0].msg', getOr(error.response.data, 'detail', errorMessage));
  }
  return errorMessage;
};
