'use client';

import dynamic from 'next/dynamic';
import { FC, useEffect, useState } from 'react';

const Chat = dynamic(() => import('src/general/components/Chat/Chat').then((mod) => mod.Chat), { ssr: false });
const Recaptcha = dynamic(() => import('src/general/components/Recaptcha/Recaptcha').then((mod) => mod.Recaptcha), {
  ssr: false,
});
const ToasterContainer = dynamic(
  () => import('src/general/components/ToasterContainer/ToasterContainer').then((mod) => mod.ToasterContainer),
  {
    ssr: false,
  },
);

/**
 * Load third party libraries such as LiveChat or Recaptcha
 */
export const ThirdPartyLibraries: FC = () => {
  const [documentReady, setDocumentReady] = useState<boolean>(false);

  useEffect(() => {
    setDocumentReady(true);
  }, []);

  if (!documentReady) {
    return null;
  }

  return (
    <>
      <Chat />
      <Recaptcha />
      <ToasterContainer />
    </>
  );
};
