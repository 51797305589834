'use client';

import classNames from 'classnames';
import { AnimatePresence, m } from 'framer-motion';
import { FC, useCallback, useState } from 'react';

import { IconCaretDown } from 'src/general/Icons/IconCaretDown';
import { pushToDataLayer } from 'src/utils/pushToDataLayer';
import { LazyMotionWrapper } from '../LazyMotionWrapper/LazyMotionWrapper';
import styles from './Accordion.module.scss';
import { AccordionItem } from './Accordion.type';

const TRANSITION_DURATION = parseInt(styles['transitionDuration'], 10) / 1000;
const INITIAL_TOP_OFFSET = -16;

const transitionVariants = {
  initial: {
    opacity: 0,
    y: INITIAL_TOP_OFFSET,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: { duration: TRANSITION_DURATION, ease: 'easeOut' },
  },
  exit: {
    opacity: 0,
    y: INITIAL_TOP_OFFSET,
    transition: { duration: TRANSITION_DURATION, ease: 'easeOut' },
  },
};

interface AccordionProps {
  className?: string;
  items: AccordionItem[];
  /** Name used to track GTM events */
  trackingName?: string;
  initialOpenIndexes?: number[];
}

export const Accordion: FC<AccordionProps> = ({ className, items, trackingName, initialOpenIndexes = [] }) => (
  <div className={classNames(styles.root, className)}>
    {items.map(({ id, header, content }, index) => {
      const initialOpenIndex = initialOpenIndexes.findIndex((value) => value === index);

      return (
        <AccordionSingle
          key={id}
          id={id}
          header={header}
          content={content}
          index={index}
          trackingName={trackingName}
          initialOpen={initialOpenIndex >= 0}
        />
      );
    })}
  </div>
);

interface AccordionItemProps extends AccordionItem, Pick<AccordionProps, 'trackingName'> {
  index: number;
  initialOpen?: boolean;
}

const AccordionSingle: FC<AccordionItemProps> = ({ id, header, content, trackingName, index, initialOpen = false }) => {
  const idHeader = `accordion-${id}-header`;

  const [isOpen, setIsOpen] = useState<boolean>(initialOpen);

  const handleClick = useCallback(() => {
    const newIsOpen = !isOpen;
    if (trackingName) {
      pushToDataLayer({ event: 'accordion_click', name: trackingName, index, open: isOpen });
    }
    setIsOpen(newIsOpen);
  }, [trackingName, index, isOpen, setIsOpen]);

  return (
    <div className={classNames(styles.accordion, 'accordion', { [styles.expanded]: isOpen, expanded: isOpen })}>
      <button
        className={classNames(styles.accordionTrigger, 'accordionTrigger')}
        id={idHeader}
        onClick={handleClick}
        aria-expanded={isOpen}
        data-testid={`accordion-${id}`}
      >
        <span className={classNames(styles.accordionHeader, 'accordionHeader', 'c-fw-bold', ' c-fs-body1')}>
          {header}
        </span>
        <span className={classNames(styles.accordionIcon)}>
          <IconCaretDown />
        </span>
      </button>

      <LazyMotionWrapper>
        <AnimatePresence initial={false}>
          <div className={classNames(styles.accordionContentWrapper)} aria-labelledby={idHeader}>
            {isOpen && (
              <m.div initial="initial" animate="enter" exit="exit" variants={transitionVariants}>
                <div className={classNames(styles.accordionContent, 'accordionContent', 'c-fw-normal', 'c-fs-body2')}>
                  {content}
                </div>
              </m.div>
            )}
          </div>
        </AnimatePresence>
      </LazyMotionWrapper>
    </div>
  );
};
