import { ComponentProps, FC, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { PluggableList } from 'react-markdown/lib';
import rehypeRaw from 'rehype-raw';
import remarkDirective from 'remark-directive';

import { Markdown } from './Markdown';
import { MarkdownLink } from './MarkdownLink';
import { Blockquote, Quote } from './Quote';
import { customDirective } from './customDirective';

const remarkPlugins: PluggableList = [remarkDirective, customDirective];
const rehypePlugins: PluggableList = [rehypeRaw];

/** @deprecated Please use the non-legacy version of this component if no HTML is passed through your markdown */
export const MarkdownLegacy: FC<ComponentProps<typeof Markdown>> = ({ className, components, children }) => (
  <ReactMarkdown
    className={className}
    remarkPlugins={remarkPlugins}
    rehypePlugins={rehypePlugins}
    components={useMemo(
      () => ({
        a: MarkdownLink,
        quote: Quote,
        blockquote: Blockquote,
        ...components,
      }),
      [components],
    )}
  >
    {children}
  </ReactMarkdown>
);
