'use client';

import classNames from 'classnames';
import { isEmpty, isNil } from 'lodash';
import { FC } from 'react';
import {
  PageProductListingFilters,
  PageProductListingOptions,
  pageProductListingFiltersValidation,
  pageProductListingOptionsValidation,
} from 'src/data/Contentful/ContentfulGetPageProductListing.types';
import { Image } from 'src/general/components/Image/Image';
import { Tabs } from 'src/general/components/Tabs/Tabs';
import { Tab } from 'src/general/components/Tabs/Tabs.type';
import { slugify } from 'src/general/helpers/slugify';
import { CarouselTabCars } from 'src/hygiene/components/CarouselTabCars/CarouselTabCars';
import { FilterListPayload } from 'src/types/CarFilters.types';
import { plpStoreFiltersToFilterParams } from 'src/utils/plp/plpStoreFiltersToFilterParams';
import { pushToDataLayer } from 'src/utils/pushToDataLayer';
import { getSectionClasses } from '../getSectionClasses.helper';
import styles from './VehicleCarousel.module.scss';
import { VehicleCarouselProps } from './VehicleCarousel.type';

type Props = VehicleCarouselProps & {
  filterData: FilterListPayload;
};

export const VehicleCarousel: FC<Props> = ({ sectionData, sectionBackground, filterData }) => {
  const tabs: Tab[] = sectionData.tabs.map((item, index) => {
    const id = `${slugify(item.title)}-${index}`;
    let filterParams: PageProductListingFilters = {};
    try {
      filterParams = JSON.parse(item.filters);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`Invalid filter data for carousel tab: ${sectionData.carouselName}>${item.title}`);
      // eslint-disable-next-line no-console
      console.error(error);
    }

    const parsedFilters = (
      pageProductListingFiltersValidation.isValidSync(filterParams) ? filterParams : {}
    ) as PageProductListingFilters;

    const parsedOptions = (
      pageProductListingOptionsValidation.isValidSync(filterParams) ? filterParams : {}
    ) as PageProductListingOptions;

    const queryParams = plpStoreFiltersToFilterParams(parsedFilters, parsedOptions, filterData);

    return {
      id,
      title: item.title,
      icon: !isEmpty(item.icon) ? <Image src={item.icon!} alt="" width={48} height={24} /> : undefined,
      content: (
        <CarouselTabCars
          carouselName={sectionData.carouselName}
          carouselValue={id}
          queryParams={queryParams}
          footerBanner={item.footerBanner}
          cta={
            item.cta?.buttonType === 'link'
              ? {
                  label: item.cta.label,
                  url: item.cta.href,
                }
              : undefined
          }
        />
      ),
      onClick: () => {
        pushToDataLayer({
          event: 'carousel_tab_change',
          carousel: sectionData.carouselName,
          tab: id,
        });
      },
    } as Tab;
  });

  return (
    <section className={classNames(styles.root, getSectionClasses(sectionBackground))}>
      {(!isNil(sectionData.title) || !isEmpty(sectionData.title)) && (
        <header className={styles.header}>
          <h2 className={classNames('c-fw-bold', 'c-fs-h4')}>{sectionData.title}</h2>
        </header>
      )}
      {tabs.length === 1 ? tabs[0].content : <Tabs tabsId={sectionData.carouselName} tabs={tabs} />}
    </section>
  );
};
