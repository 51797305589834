'use client';

import dynamic from 'next/dynamic';
import { FC } from 'react';
import { WebsiteCookies } from '../WebsiteCookies/WebsiteCookies';

const WebVitals = dynamic(() => import('src/general/components//WebVitals/WebVitals').then((mod) => mod.WebVitals), {
  ssr: false,
});
const WebsiteGlobals = dynamic(() => import('src/general/components/WebsiteGlobals/WebsiteGlobals'), { ssr: false });
const ScrollWatcher = dynamic(() => import('src/general/components/ScrollWatcher/ScrollWatcher'), { ssr: false });
const AnalyticsNavigation = dynamic(
  () => import('src/general/components/Analytics/AnalyticsNavigation').then((mod) => mod.AnalyticsNavigation),
  { ssr: false },
);

/**
 * Load Website helpers such as mini-CDP, Website globals, scroll watcher
 */
export const WebsiteHelpers: FC = () => (
  <>
    <WebsiteCookies />
    <WebVitals />
    <AnalyticsNavigation />
    <WebsiteGlobals />
    <ScrollWatcher />
  </>
);
