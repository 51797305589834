import classNames from 'classnames';
import { ComponentProps, FC } from 'react';

import { CTAButton } from 'src/general/components/Button/CTAButton';
import { Image } from 'src/general/components/Image/Image';

import styles from './PlpMarketingTile.module.scss';

type Props = Partial<{
  title: string;
  description?: string;
  image: string;
  cta: ComponentProps<typeof CTAButton>[];
  trackMarketingTileClickEvent?: () => void;
}> & { variant: 'plp' | 'carousel' };

export const PlpMarketingTile: FC<Props> = ({
  title,
  description,
  image,
  cta,
  variant,
  trackMarketingTileClickEvent,
}) => {
  const hasImage = !!image;
  const hasDescription = !!description;
  const hasCta = !!cta?.length;

  return (
    <div
      className={classNames(styles.root, { [styles.noCta]: !hasCta, [styles.carouselTile]: variant === 'carousel' })}
    >
      <div className={styles.imageContainer}>
        <div className={styles.imageWrapper}>
          {hasImage && <Image src={image} alt="" width={200} height={200} className={styles.image} />}
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={classNames('c-fs-h5', 'c-fw-bold')}>{title}</div>
        {hasDescription && <div className={classNames(styles.description, 'c-fs-body2')}>{description}</div>}
      </div>
      {hasCta && (
        <div className={classNames(styles.ctaContainer, { [styles.carouselSpacing]: variant === 'carousel' })}>
          <CTAButton
            {...cta[0]}
            size="small"
            fullWidth
            onClick={(e: React.MouseEvent<HTMLAnchorElement> & React.MouseEvent<HTMLButtonElement>) => {
              if (cta[0].onClick) {
                cta[0].onClick(e);
              }
              if (trackMarketingTileClickEvent) {
                trackMarketingTileClickEvent();
              }
            }}
          />
        </div>
      )}
    </div>
  );
};
