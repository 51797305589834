import classNames from 'classnames';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { FC } from 'react';
import { CarComparisonAdd } from 'src/car-comparison/components/CarComparisonAdd/CarComparisonAdd';
import { PROMO_4 } from 'src/constants';
import { IconWatchingPeople } from 'src/general/Icons/IconWatchingPeople';
import { AddToWatchList } from 'src/general/components/AddToWatchList/AddToWatchList';
import { ProductImage } from 'src/general/components/ProductImage/ProductImage';
import { PromoPill } from 'src/general/components/PromoPill/PromoPill';
import { Shade } from 'src/general/components/Shade/Shade';
import { formatNumber } from 'src/general/helpers/formatNumber';
import { BaseCarData } from 'src/types/Car.types';
import { StatusPill } from '../StatusPill/StatusPill';
import styles from './ProductCard.module.scss';

const ProductCardRepaymentsTooltip = dynamic(
  () => import('./ProductCardRepaymentsTooltip').then((mod) => mod.ProductCardRepaymentsTooltip),
  { ssr: false },
);

type props = {
  carData: BaseCarData;
  weeklyRepayment: number;
  defaultRate: number;
  imagePriority?: boolean;
  onCardClick?: () => void;
  onWatchlistStatusChange?: () => Promise<void>;
} & (
  | {
      variant: 'plp';
    }
  | {
      variant: 'default';
    }
);

export const ProductCard: FC<props> = ({
  carData,
  weeklyRepayment,
  defaultRate,
  imagePriority,
  onWatchlistStatusChange,
  onCardClick,
  ...props
}) => {
  const priceString = formatNumber(carData.price, { prepend: '$' });
  const dutyString = formatNumber(carData.dutyPrice, { prepend: '$' });
  const weeklyRepaymentString = formatNumber(weeklyRepayment, { prepend: '$' });
  const defaultRateString = formatNumber(defaultRate * 100, { decimals: 2, trimZeroDecimals: true, append: '%' });

  const promoPillData = () => {
    const result = [];
    if (carData.promotionTags?.includes(PROMO_4)) {
      result.push({
        key: PROMO_4,
        copy: 'Bonus Extended Coverage',
        backgroundColor: '#ebf2f5',
        color: 'rgba(21, 13, 44, 0.878431)',
      });
    }
    return result;
  };

  return (
    <div className={classNames(styles.root, styles[props.variant])}>
      <Link
        href={`/product-detail/${carData.sku}`}
        onClick={onCardClick}
        className={classNames(styles.link, 'tileLink', 'shade_container', 'shade_container--grey')}
        prefetch={false}
        data-testid={`Product_Card_${carData.sku}`}
      >
        <Shade />
        <span className="sr-only">{carData.model}</span>
      </Link>
      <ProductImage
        key={carData.sku}
        src={carData.imageUrl}
        alt={carData.model}
        className={styles.image}
        imagePriority={imagePriority}
      />
      <p className={classNames(styles.model, 'c-fs-body1', 'c-fw-bold')} data-testid="ProductCard-component-titleText">
        {carData.model}
      </p>
      <p className={classNames(styles.variant, 'c-fs-body2')} data-testid="ProductCard-component-variantText">
        {carData.variant}
      </p>
      <p className={classNames(styles.details, 'c-fs-body2')}>
        <span data-testid="ProductCard-component-distanceText">
          {formatNumber(carData.kilometers, { append: ' km' })}
        </span>
        &ensp;•&ensp;<span data-testid="ProductCard-component-transmissionText">{carData.transmission}</span>
      </p>
      <div className={styles.statusPillWrapper}>
        <StatusPill sku={carData.sku} state={carData.state} />
      </div>
      {carData.state === 'available' && <PromoPill promoPillData={promoPillData()} />}
      {props.variant === 'default' && (
        <aside className={styles.controls}>
          <AddToWatchList
            key={carData.sku}
            className={styles.watchlist}
            sku={carData.sku}
            product={{
              ...carData,
              title: carData.model,
              price: priceString,
              dutyPrice: dutyString,
            }}
            onWatchlistStatusChange={onWatchlistStatusChange}
          />
        </aside>
      )}
      <footer className={styles.footer}>
        {carData.watchers && (
          <div className={styles.watchers}>
            <IconWatchingPeople />
            <span className="c-fs-caption2">
              <b>{carData.watchers} others</b> watching this car
            </span>
          </div>
        )}
        <p className={classNames(styles.price, 'c-fs-h5', 'c-fw-bold')} data-testid="ProductCard-component-priceText">
          {priceString}
        </p>
        <div
          className={classNames(styles.repayment, 'c-fs-caption2')}
          data-testid="ProductCard-component-repaymentText"
        >
          <span>
            est. <strong>{weeklyRepaymentString}/wk</strong> based on <strong>{defaultRateString}</strong> p.a.
          </span>{' '}
          <ProductCardRepaymentsTooltip weeklyRepayment={weeklyRepaymentString} defaultRate={defaultRateString} />
        </div>

        {props.variant !== 'default' && (
          <aside className={styles.footerActions}>
            <CarComparisonAdd sku={carData.sku} className={styles.footerActionButton} />
            <AddToWatchList
              key={carData.sku}
              className={styles.footerActionButton}
              sku={carData.sku}
              product={{
                ...carData,
                title: carData.model,
                price: priceString,
                dutyPrice: dutyString,
              }}
              variant="button"
              onWatchlistStatusChange={onWatchlistStatusChange}
              buttonProps={{
                variant: 'secondary',
                fullWidth: true,
              }}
            />
          </aside>
        )}
      </footer>
    </div>
  );
};
