'use client';
import { IframeResizer } from '@open-iframe-resizer/react';
import classNames from 'classnames';
import { FC } from 'react';
import { FlexibleSection } from '../flexibleContent.type';
import { getSectionClasses } from '../getSectionClasses.helper';

interface Props extends FlexibleSection {
  sectionData: {
    formId: string;
  };
}

export const ActiveCampaignFormSection: FC<Props> = ({ sectionData, sectionBackground }: Props) => {
  const { formId } = sectionData;

  return (
    <section className={classNames(getSectionClasses(sectionBackground))}>
      <IframeResizer src={`/active-campaign/${formId}`} style={{ border: 'none', width: '100%' }} />
    </section>
  );
};
