import { FC, useEffect } from 'react';
import useSWR, { Fetcher } from 'swr';
import { shallow } from 'zustand/shallow';

import { getCheckouts } from 'src/data/CheckoutApi/CheckoutApi';
import { CheckoutApiResponse } from 'src/data/CheckoutApi/CheckoutApi.types';
import { productDetailPayloadToBaseCarData } from 'src/data/ProductApi/ProductDetailPayloadToBaseCarData';
import { getCarDetail } from 'src/data/StaticPageGeneration/PagePdpDefaults';
import { authStore } from 'src/stores/authStore';
import { cartStore } from 'src/stores/cartStore';

const DEFAULT_FOCUS_THROTTLE = 60 * 1000;

export const CartManager: FC = () => {
  const { isUserLoggedIn, userAuthDataLoading } = authStore(
    (state) => ({
      isUserLoggedIn: state.isUserLoggedIn,
      userAuthDataLoading: state.userAuthDataLoading,
    }),
    shallow,
  );
  const { apiCartData, setCarDataLoading, setCarData, setApiCartDataLoading, setApiCartData, clearCartData } =
    cartStore(
      (state) => ({
        apiCartData: state.apiCartData,
        setCarDataLoading: state.setCarDataLoading,
        setCarData: state.setCarData,
        setApiCartDataLoading: state.setApiCartDataLoading,
        setApiCartData: state.setApiCartData,
        clearCartData: state.clearCartData,
      }),
      shallow,
    );
  const loadApiCartData: Fetcher<Awaited<ReturnType<typeof getCheckouts>>, {}> = async () => {
    setApiCartDataLoading();
    // Checkout API call
    const result = await getCheckouts();
    setApiCartData(result?.length ? result[0] : null);
    return result;
  };

  const loadCartData: Fetcher<Awaited<ReturnType<typeof getCarDetail>>, CheckoutApiResponse> = async (apiCartData) => {
    setCarDataLoading();
    const sku = apiCartData.vehicle.most_recently_added_sku;
    const result = await getCarDetail(sku);

    if (result) {
      setCarData(productDetailPayloadToBaseCarData(result));
    }

    return result;
  };

  // Handle loading and refreshing of the cart API data
  useSWR(isUserLoggedIn && !userAuthDataLoading ? '/me/checkouts' : null, loadApiCartData, {
    revalidateOnFocus: true,
    focusThrottleInterval: DEFAULT_FOCUS_THROTTLE,
    refreshInterval: 0,
  });

  // Handle polling of the cart data if cart expiry isn't available
  useSWR(shouldPollCartExpiry(apiCartData), loadApiCartData, {
    revalidateOnFocus: true,
    refreshInterval: 2000,
  });

  // Handle loading and mapping of cart data
  useSWR(apiCartData, loadCartData, {
    revalidateOnFocus: true,
    focusThrottleInterval: DEFAULT_FOCUS_THROTTLE,
    refreshInterval: 0,
  });

  useEffect(() => {
    if (!isUserLoggedIn) {
      // clear the cart data
      clearCartData();
    }
  }, [isUserLoggedIn]);

  return null;
};

const shouldPollCartExpiry = (apiCartData: CheckoutApiResponse | null): 'checkoutsPoll' | null =>
  apiCartData?.vehicle.sku && !apiCartData.expiry ? 'checkoutsPoll' : null;
