import { getSha256Hash } from './getSha256Hash';

const NUM_BUCKETS = 10;

const formatUserGroup = (value: number) => {
  return `user_group-${value}`;
};

export const genRandomUserGroupId = () => {
  return formatUserGroup(Math.floor(Math.random() * NUM_BUCKETS) + 1);
};

export const getUserGroup = async (initialValue?: string, userId?: string) => {
  let groupId;
  if (userId) {
    groupId = formatUserGroup(await hashUUIDToBucket(userId));
  } else if (initialValue) {
    groupId = initialValue;
  } else {
    groupId = genRandomUserGroupId();
  }
  return groupId;
};

export const hashUUIDToBucket = async (uuid: string): Promise<number> => {
  const hash = await getSha256Hash(uuid);

  // Convert hash to a 32-bit integer
  const int32Hash = parseInt(hash, 16);

  return Math.abs(int32Hash % NUM_BUCKETS) + 1;
};
