import { carmaCsrAuthClient } from 'src/services/instance/carmaCsrAuthClient';
import { FiltersPayloadRow } from 'src/types/CarFilters.types';

export interface SavedSearch {
  id: string;
  name: string;
  filters: FiltersPayloadRow[] | [];
  // eslint-disable-next-line camelcase
  is_similar_to?: string | null;
  subscribed_at: string | null;
}

export const SAVED_SEARCH_DEFAULT_NAME = 'Saved search';

export const getSavedSearches = () =>
  carmaCsrAuthClient.get<{ searches: SavedSearch[] }>(`me/save-searches`).then((body) => body.json());

export const createSavedSearch = (search: Prettify<Pick<SavedSearch, 'name' | 'filters' | 'is_similar_to'>>) =>
  carmaCsrAuthClient.put<SavedSearch>(`me/save-searches`, { json: search }).then((body) => body.json());

export const updateSavedSearch = (
  id: string,
  search: Prettify<Partial<Pick<SavedSearch, 'name' | 'filters' | 'subscribed_at' | 'is_similar_to'>>>,
) => carmaCsrAuthClient.patch<SavedSearch>(`me/save-searches/${id}`, { json: search }).then((body) => body.json());
