import { ImageLoader } from 'next/image';
import qs from 'query-string';
import { IMAGEKIT_DEFAULT_TRANSFORM } from 'src/constants';

const DEFAULT_IMAGE_QUALITY = 70;

/**
 * Encode an image URL
 *
 * For example encode the spaces in the URL with %20
 * @param src
 */
export const encodeImageSrc = (src: string): string => {
  return src.replaceAll(' ', '%20');
};

export const imageLoaderCarma: ImageLoader = ({ src, width, quality }) => {
  const params = ['f-auto', `w-${width}`, `q-${quality ?? DEFAULT_IMAGE_QUALITY}`];
  const processedSrc = src.replaceAll(IMAGEKIT_DEFAULT_TRANSFORM, '');
  return `${processedSrc}?tr=${params.join(',')}`;
};

const imageLoaderContentful: ImageLoader = ({ src, width, quality }) => {
  const params: { [key: string]: string | number } = {
    w: width,
    q: quality ?? DEFAULT_IMAGE_QUALITY,
  };

  // No format optimisation for SVG
  if (!src.endsWith('.svg')) {
    params.fm = 'webp';
  }

  return `${src}?${qs.stringify(params)}`;
};

const imageLoaders: { [key: string]: ImageLoader } = {
  'img.carma.com.au': imageLoaderCarma,
  'images.ctfassets.net': imageLoaderContentful,
};

const imageLoadersKeys = Object.keys(imageLoaders);

export const getCarmaGenericImageLoader = (src: string = '') => {
  if (typeof src === 'string') {
    const loaderKey = imageLoadersKeys.find((item) => src.match(item));
    if (loaderKey && loaderKey in imageLoaders) {
      return imageLoaders[loaderKey];
    }
  }
  return undefined;
};

/**
 * This function is intended to be used as image loader within the NextJS Image component
 * In case the image src doesn't have a dedicated loader,
 * returns `undefined` to allow NextJS own image optimisation
 */
const carmaGenericImageLoader: ImageLoader = ({ src, width, quality }) => {
  // End early if invalid data passed
  if (typeof src !== 'string') {
    return '';
  }

  // Encode spaces in the src URL
  const encodedSrc = encodeImageSrc(src);

  // check if the src is part of the supported loaders
  const loader = getCarmaGenericImageLoader(encodedSrc);
  if (loader) {
    return loader({ src: encodedSrc, width, quality });
  }

  return `${encodedSrc}?w=${width}`;
};

// Default export required for use in next.config.js
export default carmaGenericImageLoader;
