import classNames from 'classnames';
import { ComponentProps, FC } from 'react';

import { CTAButton } from 'src/general/components/Button/CTAButton';

import { MaskedImage } from 'src/hygiene/components/MaskedImage/MaskedImage';
import { FlexibleSection } from '../flexibleContent.type';
import styles from './GenericHero.module.scss';

const SUB_HEADING_MAX_LENGTH = 40;

interface Props extends FlexibleSection {
  sectionData: {
    preHeader: string;
    header: string;
    subHeader: string;
    mobileImage: string;
    desktopImage: string;
    cta?: ComponentProps<typeof CTAButton>[];
  };
  className?: string;
}

export const GenericHero: FC<Props> = ({
  sectionData: { preHeader, header, subHeader, mobileImage, desktopImage, cta },
  className,
}) => (
  <section className={classNames(styles.root, className, 'header-transparent')}>
    <div className={styles.textContainer}>
      <div className={classNames('c-fw-ultrabold', 'c-fs-overline', styles.preHeader)}>{preHeader}</div>
      <header className={styles.headerContainer}>
        <h1 className="c-fs-d1">{header}</h1>
      </header>
      <div
        className={classNames(
          'c-fw-normal',
          styles.subHeader,
          subHeader.length <= SUB_HEADING_MAX_LENGTH ? 'c-fs-h5' : 'c-fs-body2',
        )}
      >
        {subHeader}
      </div>
      {cta && (
        <div className={styles.ctaContainer}>
          {cta.map((item) => (
            <CTAButton className={styles.cta} size="medium" key={item.label?.toString()} {...item} />
          ))}
        </div>
      )}
    </div>
    <MaskedImage
      image={mobileImage}
      width={704}
      height={526}
      className={styles.mobileMaskedImage}
      maskPosition="top"
      priority
    />
    <MaskedImage
      image={desktopImage}
      width={788}
      height={644}
      className={styles.desktopMaskedImage}
      maskPosition="left"
      priority
    />
  </section>
);
