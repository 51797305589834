import { GetStaticPaths, GetStaticProps, InferGetStaticPropsType, NextPage } from 'next';
import { ParsedUrlQuery } from 'querystring';
import { ComponentProps } from 'react';
import useSWR from 'swr';

import { ContentfulEntryId } from 'src/constants';
import { ContentfulCarmaError } from 'src/data/Contentful/ContentfulGetCarmaErrorListing';
import { FlexibleSectionPayload, getFlexibleSectionById } from 'src/data/Contentful/ContentfulGetFlexibleSection';
import { GetFooterQuery } from 'src/data/Contentful/ContentfulGetFooter';
import { GetGlobalSettings } from 'src/data/Contentful/ContentfulGetGlobalSettings';
import { ContentfulMicrocopy } from 'src/data/Contentful/ContentfulGetMicrocopyListing';
import { PageData, getPageDataBySlugLegacy } from 'src/data/Contentful/ContentfulGetPage';
import { getPageDefaults } from 'src/data/StaticPageGeneration/PageDefaults';
import { getPagePlpDefaults } from 'src/data/StaticPageGeneration/PagePlpDefaults';
import { BreadcrumbsJsonLD } from 'src/general/components/Breadcrumbs/BreadcrumbsJsonLD';
import { GoogleOneTapDynamic } from 'src/general/components/GoogleOneTap/GoogleOneTapDynamic';
import { JsonLDGlobal } from 'src/general/components/JsonLD/JsonLDGlobal';
import { HygienePageLayout } from 'src/hygiene/templates/HygienePageLayout/HygienePageLayout';
import { getPLPCarData } from 'src/services/CarService';
import { PlpStoreState } from 'src/stores/plpStoreLegacy';
import { FilterListPayload } from 'src/types/CarFilters.types';
import { ProductListDataPayload } from 'src/types/CataloguePage.types';
import { getOr } from 'src/utils/getOr';

const Index: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = ({
  initialSlug,
  footerData,
  globalSettings,
  pageData,
  awardsSectionData,
  headerData,
}) => {
  // Fetch the list of cars available.
  // We only need the total number, but must request at least 1 car object from the endpoint
  // to be a valid request.
  const { data: productList } = useSWR<ProductListDataPayload>(
    {
      pageSize: 1, // Minimum valid pageSize
    },
    getPLPCarData,
  );

  // pageData's shape should be updated in HygienePageLayout
  return (
    <>
      <JsonLDGlobal data={globalSettings.globalSettings.globalJsonLd} />
      <BreadcrumbsJsonLD items={[{ label: pageData.title, url: initialSlug }]} />
      <HygienePageLayout
        pageData={{ page: pageData }}
        footerData={footerData}
        productTotal={productList?.total}
        awardsSectionData={awardsSectionData}
        headerData={headerData}
      />
      <GoogleOneTapDynamic />
    </>
  );
};

export default Index;

interface UrlParams extends ParsedUrlQuery {
  slug: string[];
}

export const getStaticPaths: GetStaticPaths = async () => ({ paths: [], fallback: 'blocking' });

export const getStaticProps: GetStaticProps<{
  initialSlug: string;
  footerData: GetFooterQuery;
  filterData: FilterListPayload;
  contentfulMicrocopy: ContentfulMicrocopy[];
  contentfulCarmaError: ContentfulCarmaError[];
  globalSettings: GetGlobalSettings;
  pageData: PageData;
  initialPlpStoreState: Partial<PlpStoreState>;
  preview: boolean;
  awardsSectionData: FlexibleSectionPayload;
  headerData: ComponentProps<typeof HygienePageLayout>['headerData'];
}> = async (context) => {
  const { slug } = context.params as UrlParams;
  const initialSlug = slug ? `/${slug.join('/')}` : '/';
  const preview = !!context?.preview;
  const [
    { contentfulMicrocopy, footerData, contentfulCarmaError, globalSettings },
    { filterData, plpPagesMap },
    rawPageData,
    awardsSectionData,
    headerNavData,
  ] = await Promise.all([
    getPageDefaults(preview),
    getPagePlpDefaults(preview),
    getPageDataBySlugLegacy(initialSlug, preview),
    getFlexibleSectionById(ContentfulEntryId.AWARDS_SECTION),
    getFlexibleSectionById(ContentfulEntryId.HEADER_NAV_SECTION_ID),
  ]);

  let pageData: PageData | null = null;

  if (rawPageData) {
    pageData = getOr(rawPageData, 'pageCollection.items[0]', null);
  }

  // Handle 404
  if (!pageData) {
    return {
      notFound: true,
      revalidate: 60, // Revalidate every 1 minutes for not found case
    };
  }

  const initialPlpStoreState: Partial<PlpStoreState> = {
    filterData,
    plpPagesMap,
  };

  return {
    props: {
      initialSlug,
      footerData,
      filterData,
      contentfulMicrocopy,
      contentfulCarmaError,
      globalSettings,
      pageData,
      initialPlpStoreState,
      preview,
      awardsSectionData,
      headerData: headerNavData.sectionPageFlexibleSection.sectionContent,
    },
    revalidate: preview ? 1 : 5 * 60, // Revalidate every 5 minutes unless it's preview
  };
};
