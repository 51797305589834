import classNames from 'classnames';
import { FC, ReactNode } from 'react';

import { CTAButton } from 'src/general/components/Button/CTAButton';
import { Image } from 'src/general/components/Image/Image';
import { Ratings } from 'src/hygiene/components/flexible-content/Ratings/Ratings';
import { REVIEW_SOURCE } from 'src/hygiene/sections/flexible-content/CustomerReviews/CustomerReviews.types';
import { typedKeys } from 'src/utils/typedKeys';

import styles from './ReviewSummary.module.scss';
import { ReviewSummaryLink, ReviewSummaryProps } from './ReviewSummary.types';

type ReviewContent = {
  [REVIEW_SOURCE.GOOGLE]: string | ReactNode;
  [REVIEW_SOURCE.PRODUCT_REVIEW]: string | ReactNode;
};

const reviewSummaryLinks: Record<REVIEW_SOURCE, string> = {
  [REVIEW_SOURCE.GOOGLE]:
    'https://www.google.com/maps/place/Carma/@-33.9119179,151.1852528,17z/data=!4m8!3m7!1s0x4605ff28a07c8029:0x939905e9cf86b1fc!8m2!3d-33.9119224!4d151.1878277!9m1!1b1!16s%2Fg%2F11smr6d02r?hl=en-US&entry=ttu',
  [REVIEW_SOURCE.PRODUCT_REVIEW]: 'https://www.productreview.com.au/listings/carma',
};

interface Props extends ReviewSummaryProps {
  ctaLabel: ReactNode;
  counts: ReviewContent;
  averageRatings: ReviewContent;
  isShowingCta?: boolean;
}

export const ReviewSummary: FC<Props> = ({
  className,
  classNameCard,
  sectionData: { linksType = 'default', links = null },
  ctaLabel,
  counts,
  averageRatings,
  isShowingCta = true,
  classNameContentLayout,
  classNameContainerLayout,
  classNameImageContainer,
}) => {
  const linksData: Record<REVIEW_SOURCE, ReviewSummaryLink> =
    linksType === 'custom' && links
      ? links
      : {
          [REVIEW_SOURCE.GOOGLE]: {
            label: ctaLabel,
            url: reviewSummaryLinks[REVIEW_SOURCE.GOOGLE],
          },
          [REVIEW_SOURCE.PRODUCT_REVIEW]: {
            label: ctaLabel,
            url: reviewSummaryLinks[REVIEW_SOURCE.PRODUCT_REVIEW],
          },
        };

  return (
    <section className={classNames(styles.root, className)}>
      <div className={classNames(styles.container, classNameContainerLayout)}>
        {typedKeys(reviewSummaryLinks).map((reviewSource) => (
          <div className={classNames(styles.summary, classNameCard)} key={reviewSource}>
            <div className={classNames(styles.content, classNameContentLayout)}>
              <div className={classNames(styles.imageContainer, classNameImageContainer)}>
                <Image
                  src={
                    reviewSource === REVIEW_SOURCE.GOOGLE
                      ? '/images/components/ReviewCard/google.svg'
                      : '/images/components/ReviewCard/productReview.svg'
                  }
                  width={144}
                  height={24}
                  className={styles.image}
                  alt=""
                />
              </div>
              <div className={styles.ratingInfoContainer}>
                <Ratings rating={Number(averageRatings[reviewSource])} />
                <div className={classNames(styles.averageRating, 'c-fw-bold', 'c-fs-body2')}>
                  {averageRatings[reviewSource]}
                </div>
                <span className={classNames('c-fw-normal', 'c-fs-body2')}>({counts[reviewSource]})</span>
              </div>
            </div>
            {linksType !== 'none' && isShowingCta && (
              <CTAButton
                className={styles.button}
                label={linksData[reviewSource].label}
                buttonType="link"
                href={linksData[reviewSource].url}
                variant="link"
                size="small"
              />
            )}
          </div>
        ))}
      </div>
    </section>
  );
};
