import { isEmpty } from 'lodash';
import { FC, ReactNode, useMemo } from 'react';
import useSWRImmutable from 'swr/immutable';

import { productDetailPayloadToBaseCarData } from 'src/data/ProductApi/ProductDetailPayloadToBaseCarData';
import { IconCaretRight } from 'src/general/Icons/IconCaretRight';
import { Button } from 'src/general/components/Button/Button';
import { CarouselSection } from 'src/general/components/Carousel/CarouselSection';
import { ConditionalWrapper } from 'src/general/components/ConditionalWrapper/ConditionalWrapper';
import { ProductCard } from 'src/plp/components/ProductCard/ProductCard';
import { ProductCardLoading } from 'src/plp/components/ProductCard/ProductCardLoading';
import { getPLPCarData } from 'src/services/CarService';
import { PlpFilterParams } from 'src/types/CarFilters.types';
import { ProductListDataPayload } from 'src/types/CataloguePage.types';
import { parseFilterParams } from 'src/utils/filterHelper';
import { pushToDataLayer, pushToDataLayerProductCard } from 'src/utils/pushToDataLayer';

import classes from './CarouselTabCars.module.scss';

const loadingTiles = [...Array(4).keys()].map((item) => ({
  id: `${item}`,
  tile: <ProductCardLoading key={`loading-${item}`} variant="default" />,
}));

interface CarouselTabCarsProps {
  /** Name used to track GTM events */
  carouselName?: string;
  /** Name used to track GTM events */
  carouselValue?: string;
  queryParams: PlpFilterParams | null;
  footerBanner?: string;
  cta?: {
    label: ReactNode;
    url: string;
  };
}

export const CarouselTabCars: FC<CarouselTabCarsProps> = ({
  queryParams,
  footerBanner,
  cta,
  carouselName,
  carouselValue,
}) => {
  const { data: productList } = useSWRImmutable<ProductListDataPayload>(
    queryParams === null
      ? null
      : {
          pageSize: 12,
          filters: parseFilterParams({ ...queryParams, state: 'available' }),
          sort: queryParams.sortBy,
        },
    getPLPCarData,
  );

  const tiles = useMemo(
    () =>
      productList && !isEmpty(productList.results)
        ? productList.results.map((product, index) => ({
            id: product.sku,
            tile: (
              <ProductCard
                carData={productDetailPayloadToBaseCarData(product)}
                weeklyRepayment={product.weeklyRepayment}
                defaultRate={product.defaultRate}
                variant="default"
                onCardClick={
                  carouselName && carouselValue
                    ? () => {
                        pushToDataLayerProductCard(product, index, {
                          event: 'carousel_tile_click',
                          name: carouselName,
                          value: carouselValue,
                        });
                      }
                    : undefined
                }
              />
            ),
          }))
        : loadingTiles,
    [productList?.results],
  );

  const hasFooterBanner = !isEmpty(footerBanner);

  return (
    <>
      <CarouselSection tiles={tiles} key={`${carouselValue}-${tiles.length}`} carouselName={carouselName} />
      {cta && (
        <footer className={classes.footer}>
          <ConditionalWrapper
            condition={hasFooterBanner}
            wrapper={(children) => (
              <div className={classes.footerBanner}>
                <div className={classes.footerBannerContent}>{footerBanner}</div>
                {children}
              </div>
            )}
          >
            <Button
              href={cta.url}
              variant={hasFooterBanner ? 'secondary' : 'primary'}
              size="medium"
              endIcon={<IconCaretRight />}
              onClick={() => {
                if (!isEmpty(carouselName) && !isEmpty(carouselValue)) {
                  pushToDataLayer({ event: 'carousel_footer_cta_click', name: carouselName, value: carouselValue });
                }
              }}
            >
              {cta.label}
            </Button>
          </ConditionalWrapper>
        </footer>
      )}
    </>
  );
};
